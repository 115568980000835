<template>
  <div
    class="component-header-top d-flex justify-content-between align-items-center"
  >
    <div class="seach-global">
      <el-input :placeholder="translateLanguage.search" v-model="seach">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>

  <div class="d-flex align-items-center">

    <div>
      <el-select
        v-model="accountInfo.language"
        placeholder="Select"
        @change="changeLang()"
      >
      <el-option
        v-for="item in langList"
        :key="item.value"
        :label="item.name"
        :value="item.value">
      </el-option>
  </el-select>
    </div>

    <div
      class="dropdown-account"
      ref="dropdownProfile"
      @click="showMenuTop = !showMenuTop"
    >
      <img
        v-if="accountInfo.avatar"
        :src="baseMedia + accountInfo.avatar"
        class="avatar"
      />
      <span v-else><img src="@/static/icon/header-top/account.svg" /></span>
      <span class="name">
        {{ accountInfo.name }}( {{ accountInfo.warehouse_text }} )
        <img class="down" src="@/static/icon/header-top/down.svg" />
      </span>
      <ul v-if="showMenuTop">
        <router-link
          :to="{ name: 'account-profile', params: {} }"
          @click.native.stop="showMenuTop = false"
        >
          <span>{{ accountInfo.langByLanguage.app_account_information }}</span>
        </router-link>
        <!-- <router-link
          :to="{ name: 'account-reset-pass', params: {} }"
          @click.native.stop="showMenuTop = false"
        >
          <span>Đổi mật khẩu</span>
        </router-link> -->
        <a class="divider"></a>
        <a @click.stop.stop="confirmLogout">
          <span>{{ accountInfo.langByLanguage.account_sign_out }}</span>
        </a>
      </ul>
    </div>

  </div>
  </div>
</template>

<script>
import axios from "axios";
import ls from "local-storage";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LayoutHeaderTop",
  data() {
    return {
      seach: "",
      showMenuTop: false,
      langList: [
        {
          name: "Tiếng việt",
          value: "vi"
        },
        {
          name: "English",
          value: "en"
        },
        {
          name: "中国人",
          value: "cn"
        },
      ],
      translateLanguage:{
        search: ''
      },
      baseMedia: process.env.VUE_APP_MEDIA,
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo"]),
  },
  mounted() {
    document.addEventListener("click", this.documentClick);
    this.checkLanguage();
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  methods: {
    checkLanguage(){
      if (this.accountInfo.language === 'en') {
        this.translateLanguage.search = 'Search for bill of lading code V.39';
      } else if (this.accountInfo.language === 'cn') {
        this.translateLanguage.search = '搜索提单代码 V.39';
      } else if (this.accountInfo.language === 'vi') {
        this.translateLanguage.search = 'Tìm kiếm mã vận đơn V.39';
      }
    },
    documentClick(e) {
      let el = this.$refs.dropdownProfile;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.showMenuTop = false;
      }
    },

    ...mapActions("account", ["removeAccountInfo"], ["getAccountInfo"]),
    mouseLeave() {
      this.showMenuTop = false;
    },
    confirmLogout() {
      let confirmText = "Xác nhận thoát tài khoản";
      if (this.accountInfo.language === "en") {
        confirmText = "Confirm account exit";
      }
      if (this.accountInfo.language === "cn") {
        confirmText = "确认账户退出";
      }
      this.$confirm(confirmText, "Warning", {
        confirmButtonText: this.accountInfo.langByLanguage.common_ok,
        cancelButtonText: this.accountInfo.langByLanguage.common_cancel,
        type: "warning",
      })
        .then(() => {
          ls('out', true);
          this.removeAccountInfo();
        })
        .catch(() => {});
    },
    // đổi ngôn ngữ
    changeLang() {
      this.canSubmit = false;
      let data = {
        language: this.accountInfo.language,
      }
      axios.post("user/change-language", data).then((response) => {
        this.canSubmit = true;
        switch (response.code) {
          case 200:
            location.reload()
            // this.$message({
            //   message: "Thành công",
            //   type: "success",
            // });
            break;
          case 422:
            this.$notify({
              title: "Vui lòng kiểm tra lại thông tin",
              message: response.message,
              type: "warning",
            });
            break;
          case 400:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.errors[0],
              type: "warning",
            });
            break;
            case 500:
              this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
              });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.component-header-top {
  height: 50px;
  padding: 0px 15px;
}
.seach-global {
  min-width: 280px;
}

.dropdown-account {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0px 3px 0px 10px;
  position: relative;
}
.dropdown-account img {
  margin-right: 8px;
}
.dropdown-account .down {
  width: 10px;
  margin-right: 0px;
  margin-left: 5px;
}
.dropdown-account .name {
  color: #fff;
  font-size: 12px;
}
.dropdown-account ul {
  position: absolute;
  top: 50px;
  right: 0px;
  margin: 0px;
  width: 200px;
  background-color: #fff;
  padding: 5px 0px 5px 0px;
  z-index: 1000;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.dropdown-account ul a {
  list-style-type: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-decoration: none;
  color: #2c3e50;
}
.dropdown-account ul a:hover {
  background-color: #f2f2f2;
}
.dropdown-account ul a.divider {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 5px;
}
</style>
<style type="text/css">
.component-header-top input {
  border-radius: 25px;
  height: 28px;
  font-size: 12px;
  border: none;
}
.component-header-top .el-input__icon {
  line-height: 28px;
}
.avatar {
  max-width: 100%;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>
